import React, { Fragment } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { below, Grid } from "../../styles"

const SmartThingsBar = () => {
  const {
    alexaLogo,
    googleAssistantLogo,
    nestLogo,
    iftttBlueLogo,
    smartThingsLogo,
  } = useStaticQuery(graphql`
    query {
      alexaLogo: file(relativePath: { eq: "logos/logo-alexa.png" }) {
        ...fixedImage200
      }
      googleAssistantLogo: file(
        relativePath: { eq: "logos/logo-google-pill.png" }
      ) {
        ...fixedImage200
      }
      nestLogo: file(relativePath: { eq: "logos/logo-nest.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 107
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
        }
      }
      iftttBlueLogo: file(relativePath: { eq: "logos/logo-ifttt-blue.png" }) {
        ...fixedImage200
      }
      smartThingsLogo: file(
        relativePath: { eq: "logos/logo-smartthings.png" }
      ) {
        ...fixedImage200
      }
    }
  `)

  const logos: { link: string, image: IGatsbyImageData, alt: string, isExternalLink ?: boolean }[] = [
    {
      link: "/alexa/",
      image: alexaLogo.childImageSharp.gatsbyImageData,
      alt: "Amazon Alexa",
    },
    {
      isExternalLink: true,
      link:
        "https://support.rachio.com/hc/en-us/articles/115010378907-How-do-I-connect-my-Google-Home-to-Rachio-/",
      image: googleAssistantLogo.childImageSharp.gatsbyImageData,
      alt: "Google Assistant",
    },
    {
      link: "/works-with-nest/",
      image: nestLogo.childImageSharp.gatsbyImageData,
      alt: "Nest",
    },
    {
      link: "/ifttt/",
      image: iftttBlueLogo.childImageSharp.gatsbyImageData,
      alt: "IFTTT",
    },
    {
      link: "/smartthings/",
      image: smartThingsLogo.childImageSharp.gatsbyImageData,
      alt: "SmartThings",
    },
  ]

  return (
    <GridFlexDiv cols={[1, 2, 3]}>
      {logos
        .filter(logo => logo)
        .map(logo => (
          <div key={logo.alt}>
            {logo.isExternalLink ? (
              <a
                href={logo.link}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <GatsbyImage
                  image={getImage(logo.image) as IGatsbyImageData}
                  alt={logo.alt}
                />
              </a>
            ) : (
              <Link to={logo.link}>
                <GatsbyImage
                  image={getImage(logo.image) as IGatsbyImageData}
                  alt={logo.alt}
                />
              </Link>
            )}
          </div>
        ))}
    </GridFlexDiv>
  )
}

export default SmartThingsBar

const GridFlexDiv = styled(Grid)`
  background: var(--lightesterGray);
  padding: var(--basePadding);
  justify-items: center;
`
