import React from "react"
import styled from "styled-components"
import { below } from "../../styles"

type Spec = {
  title: string
  description?: string
  descriptionList?: string[]
}

type Props = {
  specs: Spec[]
}

const SpecChart = ({ specs }: Props) => (
  <Table>
    <tbody>
      {specs.map((spec: Spec) => (
        <tr key={spec.title}>
          <th>
            <p className="large">{spec.title}</p>
          </th>
          <td>
            {spec.description && <p className="large">{spec.description}</p>}
            {spec.descriptionList && (
              <ul>
                {spec.descriptionList.map(item => (
                  <li key={item}>
                    <p className="large" dangerouslySetInnerHTML={{ __html: `- ${item}` }}></p>
                  </li>
                ))}
              </ul>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
)

export default SpecChart

const Table = styled.table`
  margin: var(--margins);
  text-align: left;
  border-collapse: collapse;
  td p {
    margin-left: 3rem;
  }
  tr {
    border-bottom: 1px solid var(--lightestGray);
    th {
      vertical-align: baseline;
    }
    td {
      li {
        p {
          margin: 1rem 0 1rem 3rem;
        }
        &:first-child p {
          margin-top: 4rem;
        }
        &:last-child p {
          margin-bottom: 4rem;
        }
      }
    }
  }
  ${below.medium`
  th,
  td {
    display: block;
  }
  th p {
    margin-bottom: 0;
  }
  tr td p, tr td li p {
    margin-left: 0;
  }
  `};
`
