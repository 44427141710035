import React, { useState } from "react"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { Grid } from "../styles"

type ProductImage = {
  image: IGatsbyImageData,
  alt: string
}

type Props = {
  productImages: ProductImage[]
}

type CarouselThumbnailProps = {
  productImage: ProductImage
  index: number
  imageIndex: number
  changeImage: (index: number) => void
}
const CarouselThumbnail = ({ productImage, index, imageIndex, changeImage }: CarouselThumbnailProps) => {
  return (
    <ThumbnailContainer
      isActive={index === imageIndex}
      style={{ position: 'relative' }}
      key={productImage.alt}
      onClick={() => changeImage(index)}
    >
      <GatsbyImage
        image={getImage(productImage.image) as IGatsbyImageData}
        alt={`${productImage.alt} thumbnail`}
      />
    </ThumbnailContainer>
  )
}

const Carousel = ({ productImages }: Props) => {
  if (!productImages) return null

  const [[image, imageIndex], setImage] = useState([productImages[0], 0])
  const [isShowing, setIsShowing] = useState(true)

  const changeImage = (index: number) => {
    setTimeout(() => setIsShowing(true), 100)
    setIsShowing(false)
    setImage([productImages[index], index])
  }

  return (
    <div>
      <GatsbyImage
        image={getImage(image.image) as IGatsbyImageData}
        alt={image.alt}
        style={{
          opacity: isShowing ? 1 : 0,
          transition: "0.3s ease-in-out opacity",
        }}
      />

      <Grid cols={productImages.length} css="margin: 3rem 0; grid-gap: 0">
        {productImages.map((img, index) => (
          <CarouselThumbnail
            key={`carousel-image-${index}`}
            productImage={img}
            index={index}
            imageIndex={imageIndex}
            changeImage={changeImage}
          />
        ))}
      </Grid>
    </div>
  )
}

export default Carousel

const ThumbnailContainer = styled.div`
  cursor: pointer;
  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    css`
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #00a7e1;
        opacity: 0.2;
      }
    `}
`
