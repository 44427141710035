import React from "react"
import { graphql, Link } from "gatsby"
import { Wrapper, Container, Grid, Card, media, below } from "../styles"
import styled, { css } from "styled-components"
import IconBar from "../components/IconBar"
import SEO from "../components/seo"
import Icon from "../components/Icon"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Button } from "../components/Button"
import Carousel from "../components/Carousel"
import SpecChart from "../components/legacy/SpecChart"
import SmartThingsBar from "../components/legacy/SmartThingsBar"
import { usePowerReviews } from "../hooks"
import r3PegboardLargeBgImg from "../images/pages/product/product-blade-rachio3-pegboard-880.jpg"
import weatherIntelImg from "../images/pages/product/blade-weather-intel-houses.jpg"

const RachioThreePage = ({ data }) => {
  usePowerReviews("rachio-3")

  const productImages = [
    {
      image: data.r3Img.childImageSharp.gatsbyImageData,
      thumbnail: data.r3Thumbnail.childImageSharp.gatsbyImageData,
      alt: "Rachio 3 Controller with light on",
    },
    {
      image: data.r3ContentsImg.childImageSharp.gatsbyImageData,
      thumbnail: data.r3ContentsThumbnail.childImageSharp.gatsbyImageData,
      alt: "Rachio 3 Controller box contents",
    },
    {
      image: data.r3InsideImg.childImageSharp.gatsbyImageData,
      thumbnail: data.r3InsideThumbnail.childImageSharp.gatsbyImageData,
      alt: "View inside Rachio 3 Controller",
    },
    {
      image: data.r3LightOffImg.childImageSharp.gatsbyImageData,
      thumbnail: data.r3LightOffThumbnail.childImageSharp.gatsbyImageData,
      alt: "Rachio 3 Controller with light off",
    },
  ]
  return (
    <>
      <SEO
        title="Rachio 3 Smart Wi-Fi Sprinkler Controller"
        description="The Rachio 3 Smart Sprinkler Controller expands on our best-selling Rachio Generation 2 Smart Sprinkler Controller with a sleek new look, game-changing technology and upgraded Weather Intelligence™ Plus. Pair the Wireless Flow Meter with Rachio 3 for the ultimate Rachio Smart Watering System."
        pathname="/rachio-3/"
      />
      <Wrapper>
        <Grid cols={[1, 1, 2]} css="padding-top: 0">
          <Container css={below.medium` padding-bottom: 0`}>
            <Carousel productImages={productImages} />
          </Container>

          <div>
            <h6
              css={`
                margin: var(--margins);
                ${below.medium`
                margin-top: 0;
              `}
              `}
            >
              Smart Sprinkler Controller
            </h6>
            <h1 css="color: var(--primaryColor); margin-top: 0">Rachio 3</h1>
            <div id="pr-reviewsnippet" />
            <p className="large">
              The Rachio 3 Smart Sprinkler Controller raises the smart watering
              bar with foolproof installation, dual-band Wi-Fi and an expressive
              light bar. Rachio 3 is the only controller equipped with
              hyperlocal Weather Intelligence™ Plus.
            </p>
            <Grid cols="2">
              <Card css="text-align: center; padding: 2rem;">
                <p css="margin: 0">8-Zone MSRP</p>
                <h3 css="margin: 0; color: var(--primaryColor)">$229</h3>
                <p className="subtext">8ZULWC-PRO</p>
              </Card>
              <Card css="text-align: center; padding: 2rem;">
                <p css="margin: 0">16-Zone MSRP</p>
                <h3 css="margin: 0; color: var(--primaryColor)">$279</h3>
                <p className="subtext">16ZULWC-PRO</p>
              </Card>
            </Grid>
            <Button as={Link} css="width: 100%; margin: 3rem 0" to="/buy/">
              Shop Rachio Pro Store
            </Button>
          </div>
        </Grid>
      </Wrapper>
      <IconBar icons={icons} isBlue />
      <Wrapper>
        <ReverseGrid cols={[1, 1, 2]}>
          <div className="mobile-reverse-item">
            <h2
              css={`
                color: var(--primaryColor);
                ${below.medium`
                margin-top: 0;
              `};
              `}
            >
              Start watering smarter, faster.
            </h2>
            <p>
              Our engineers examined every detail of Rachio 3 to streamline and
              shave minutes off your installation. Ready, set, water.
            </p>
            <IconList>
              <li>
                <Icon color="var(--primaryColor)" name="tap" />
                <p css="display: inline">
                  Easy-press connectors make wiring a snap.
                </p>
              </li>
              <li>
                <Icon
                  style={{ width: "36px", height: "36px", marginRight: "1rem" }}
                  color="var(--primaryColor)"
                  name="toggle-calibrate"
                />
                <p>Connect with both 14 &amp; 18 gauge wires.</p>
              </li>
              <li>
                <Icon color="var(--primaryColor)" name="magnet" />
                <p>Magnetic cover locks right into place.</p>
              </li>
              <li>
                <Icon color="var(--primaryColor)" name="support" />
                <p>
                  We’re here to help if you need it - but we’re betting you
                  won’t.
                </p>
              </li>
            </IconList>
          </div>
          <div>
            <GatsbyImage
              image={getImage(data.r3InsideImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
              alt="Inside view of Rachio 3 Smart Sprinkler Controller"
            />
          </div>
        </ReverseGrid>

        <ImgContainer img={r3PegboardLargeBgImg}>
          <Wrapper
            as={Grid}
            cols={[1, 1, 2]}
            css={`
              ${below.medium`
                grid-gap: 0;
                padding: 0;
                h2 {
                  margin-top: 0;
                }
              `}
            `}
          >
            <Container>
              <MobileProductImg
                image={getImage(data.pegboardMobileImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
                alt="Rachio controller on a peg board"
              />
            </Container>
            <Container>
              <h2>Connect and expand.</h2>
              <p>
                Rachio 3 is the most connected controller on the market,
                designed to extend your clients' smart home outdoors.
              </p>
              <IconList>
                <li>
                  <Icon color="var(--primaryColor)" name="iot-nest" />
                  <p>Works with favorite smart home devices, platforms.</p>
                </li>
                <li>
                  <Icon color="var(--primaryColor)" name="wifi" />
                  <p>Dual band Wi-Fi (2.4 GHz / 5 GHz).</p>
                </li>
                <li>
                  <Icon color="var(--primaryColor)" name="radio-waves" />
                  <p>Compatible with both Android and iOS platforms.</p>
                </li>
              </IconList>
            </Container>
          </Wrapper>
        </ImgContainer>
        <SmartThingsBar />
      </Wrapper>
      <Wrapper width="tight" css="text-align: center">
        <h2 css="color: var(--primaryColor)">
          Worry-free watering with the Rachio App.
        </h2>
        <p className="large">
          Your personal watering assistant helps you monitor and manage watering
          from anywhere with robust smartphone control. Create perfectly
          tailored schedules, make automatic weather adjustments and maintain
          the perfect yard - without lifting a finger.
        </p>
      </Wrapper>
      <Wrapper>
        <ReverseGrid cols={[1, 1, 2]} css="align-items: center">
          <div>
            <h2>Water smarter with best-in-class features.</h2>

            <IconList>
              <li>
                <Icon color="var(--primaryColor)" name="timer" />
                <p>
                  Precise remote control with run, pause, skip or Quick Run.
                </p>
              </li>
              <li>
                <Icon color="var(--primaryColor)" name="calendar-clock" />
                <p>
                  Guided schedule setup creates tailored, accurate schedules.
                </p>
              </li>
              <li>
                <Icon color="var(--primaryColor)" name="weather-intel" />
                <p>
                  Automatic, hyperlocal schedule adjustments with Weather
                  Intelligence™ Plus.
                </p>
              </li>
              <li>
                <Icon color="var(--primaryColor)" name="artificial-intel" />
                <p>Advanced Rachio technology.</p>
              </li>
            </IconList>
          </div>
          <div>
            <GatsbyImage
              css="max-width: 380px; margin: 0 auto"
              image={getImage(data.phoneAppImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
              alt="Rachio app"
            />
          </div>
        </ReverseGrid>

        <ImgContainer img={weatherIntelImg}>
          <Wrapper
            as={Grid}
            cols={[1, 1, 2]}
            css={`
              ${below.medium`
                grid-gap: 0;
                padding: 0;
                > div {
                  padding-bottom: 0;
                }
              `}
            `}
          >
            <Container
              css={`
                ${media.medium`
                color: var(--white);
              `}
              `}
            >
              <h3>Weather Intelligence&trade; Plus, a Rachio 3 exclusive.</h3>
              <p>
                Premium Weather Intelligence Plus pinpoints the forecast - then
                adjusts watering accordingly.
              </p>
              <p>
                Using comprehensive satellite, radar and weather station data
                from more than a quarter million sources, Rachio will never be
                caught watering in the rain, wind or snow.
              </p>
            </Container>
            <Container>
              <MobileProductImg
                image={getImage(data.weatherIntelMobileImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
                alt="Weather Intel background image"
              />
            </Container>
          </Wrapper>
        </ImgContainer>
      </Wrapper>
      <Wrapper width="tight" css="text-align: center">
        <h3 css="color: var(--primaryBlue)">
          Three steps to smarter watering.
        </h3>
        <p className="large">
          Installing the Rachio 3 Smart Sprinkler Controller can take only 30
          minutes or less, no special tools required. Guided setup and one-touch
          connectors make getting started simple - breeze through installation
          in just three easy steps!
        </p>
        <IconBar icons={stepIcons} />
      </Wrapper>
      <div css="background: var(--lightesterGray)">
        <Wrapper width="tight">
          <Container
            css={below.medium`
            padding-top: 6rem;
          `}
          >
            <h2 css="text-align: center; margin-top: 0">
              Rachio 3 Technical Specifications
            </h2>
            <SpecChart specs={r3Specs} />
          </Container>
        </Wrapper>
      </div>
      {/* <Wrapper width="tight" className="center-text" id="product-review">
        <div className="top-padding">
          <h2 className="blue-text">User Reviews</h2>
          <p>
            <strong>
              All of our reviews are submitted by real Rachio users.
            </strong>
          </p>
          <p>
            We never use incentives to influence these reviews or alter their
            content in any way. Read to learn how customers rate our products on
            ease of setup and use.
          </p>
        </div>
      </Wrapper> */}
      {/* <Wrapper>
        <PowerReviewsContainer>
          <div id="pr-reviewdisplay" />
        </PowerReviewsContainer>
      </Wrapper> */}
    </>
  )
}

export default RachioThreePage

const icons = [
  {
    iconName: "support",
    title: "Best-in-class support",
  },
  {
    iconName: "truck",
    title: "Free shipping & returns",
  },
  {
    iconName: "heart",
    title: "Extended 4-Year Warranty on Pro Units",
  },
  {
    isRebate: true,
    iconName: "money",
    title: "Rebates available",
  },
]

const stepIcons = [
  {
    iconName: "replace-controller",
    title: "1. Replace your customers old controller.",
  },
  {
    iconName: "plug",
    title: "2. Plug existing wires into Rachio.",
  },
  {
    iconName: "app-touch",
    title: "3. Complete setup in the Rachio app.",
  },
]

export const r3Specs = [
  {
    title: "In the box",
    descriptionList: [
      "Rachio 3 Smart Sprinkler Controller",
      "Power supply with 6-ft cord",
      "Mounting hardware",
      "Quick start guide",
    ],
  },
  {
    title: "App Compatibility",
    description:
      "iOS 10.3+ and Android 4.4+; subject to change. Mobile app is required to connect the controller to Wi-Fi. Web app available on most browsers",
  },
  {
    title: "Integrations",
    description:
      "Works with Nest, Amazon Alexa, The Google Assistant, IFTTT, Wink, Control4, Nexia and others",
  },
  {
    title: "Zones",
    description: "8-zone and 16-zone models",
  },
  {
    title: "Size and Weight",
    descriptionList: ["Dimensions: 9.1” x 5.5” x 1.4”", "Weight: 1.05 Lbs"],
  },
  {
    title: "Power requirements",
    description:
      "The Rachio controller must be connected to the AC power adapter included. DC Transformers are not supported",
    descriptionList: [
      "Power Supply: External Transformer (6-ft cord - 2.1mm x 5.5mm Female barrel plug)",
      "Transformer input: 120 VAC ~60Hz 300mA",
      "Transformer output: 24 VAC 1000mA",
      "Zone output (24 VAC): Compatible with 24VAC Solenoids",
    ],
  },
  {
    title: "Operating Temperature",
    description: "Operating temperature: -4°F to 140°F",
  },
  {
    title: "Wi-Fi Connection",
    description:
      "2.4 or 5 GHz wireless network signal available at the installation location",
  },
  {
    title: "Sensors",
    descriptionList: [
      "Rain Sensor: Wired & Wireless Normally Closed (NC) sensors",
      "Soil Sensor: Wired & Wireless Normally Closed (NC) sensors",
      "Wired Flow Sensors",
    ],
  },
  {
    title: "Wire Terminals",
    description:
      "Smart wire terminals sense which zones are active, and provide feedback if your customers irrigation system might have a problem",
  },
  {
    title: "Master Valve",
    description: "Compatible with master valve, pump relay, or indexing valve",
  },
  {
    title: "Warranty",
    description: "Extended 4-year warranty when purchasing Pro Units/SKUs",
  },
  {
    title: "Power Disruption",
    description:
      "In the event of Wi-Fi outage or intermittency, the controller’s memory retains the last saved schedule received from the Rachio cloud and maintains the schedule until an update can be downloaded",
  },
  {
    title: "Optional Outdoor Enclosure",
    description:
      "Custom outdoor enclosure is available - Enclosure is weatherproof and allows for hardwiring",
  },
]

export const query = graphql`
  query {
    # Masthead images
    ## Image Note: Actual image is 1300px wide
    r3Img: file(relativePath: { eq: "products/r3/R38_front_light.jpg" }) {
      ...fluidImage790
    }
    r3InsideImg: file(
      relativePath: { eq: "products/r3/R38_inside_front.jpg" }
    ) {
      ...fluidImage790
    }
    r3ContentsImg: file(
      relativePath: { eq: "products/r3/R38_box_contents.jpg" }
    ) {
      ...fluidImage790
    }
    r3AngledImg: file(relativePath: { eq: "products/r3/r3-angled.jpg" }) {
      ...fluidImage790
    }
    r3LightOffImg: file(relativePath: { eq: "products/r3/R316_front.jpg" }) {
      ...fluidImage790
    }
    # Masthead thumbnails
    r3Thumbnail: file(relativePath: { eq: "products/r3/R38_front_light.jpg" }) {
      ...fixedImage75x75
    }
    r3InsideThumbnail: file(
      relativePath: { eq: "products/r3/R38_box_contents.jpg" }
    ) {
      ...fixedImage75x75
    }
    r3ContentsThumbnail: file(
      relativePath: { eq: "products/r3/R38_box_contents.jpg" }
    ) {
      ...fixedImage75x75
    }
    r3AngledThumbnail: file(relativePath: { eq: "products/r3/r3-angled.jpg" }) {
      ...fixedImage75x75
    }
    r3LightOffThumbnail: file(
      relativePath: { eq: "products/r3/R316_front.jpg" }
    ) {
      ...fixedImage75x75
    }
    #
    pegboardMobileImg: file(
      relativePath: {
        eq: "pages/product/product-blade-rachio3-pegboard-mobile.jpg"
      }
    ) {
      ...fullFluidImage
    }
    ## Image note: width is 700 but needs to be 790
    weatherIntelMobileImg: file(
      relativePath: {
        eq: "pages/product/blade-weather-intel-houses-mobile.jpg"
      }
    ) {
      ...fluidImage790
    }
    #
    phoneAppImg: file(relativePath: { eq: "pages/product/phone-app.png" }) {
      ...fluidImage380
    }
    #
    r3FlowPhoneImg: file(
      relativePath: { eq: "pages/product/product-rachio3-flow-phone.png" }
    ) {
      ...fluidImage790
    }
  }
`

const PowerReviewsContainer = styled(Container)`
  /* Hide the customer provided review images and gallery */
  & #pr-image-display,
  & .pr-rd-images {
    display: none;
  }
`

export const MobileProductImg = styled(GatsbyImage)`
  display: none;
  ${below.medium`
    display: block;
  `};
`

export const SmallProductImg = styled(GatsbyImage)`
  display: none;

  ${below.small`
    display: block;
  `};
`

const ImgContainer = styled.div`
  ${({ img }: { img: string }) => css`
    background: none;
    ${media.medium`
    background: url(${img}) no-repeat;
    background-size: cover;
  `}
  `};
`

const ReverseGrid = styled(Grid)`
  align-items: center;
  padding: var(--topSectionPadding) 0 var(--bottomSectionPadding) 0;
  ${below.medium`
    .mobile-reverse-item  {
      grid-row: 2;
    }
  `};
`

const IconList = styled.ul`
  li {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    svg {
      flex: none;
      height: 36px;
      width: 36px;
      margin-right: 1rem;
    }
  }
`
